import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'

function SEO({ seomatic }) {
  let metaTagsWithoutLocale = seomatic.metaTagContainer.replace("<meta content=\"en_US\" property=\"og:locale\">", "");
  let metaTagsWithoutLocaleAndRobots = metaTagsWithoutLocale.replace("<meta name=\"robots\" content=\"all\">", "");
  
  return (
    <Helmet>
      {ReactHtmlParser([
        seomatic.metaTitleContainer,
        metaTagsWithoutLocaleAndRobots
      ])}
      <meta content="de_DE" property="og:locale"/>
      <meta name="robots" content="index, follow, max-image-preview:standard, max-snippet:-1, max-video-preview:-1" />
    </Helmet>
  )
}

SEO.defaultProps = {
  seomatic: ``
}

SEO.propTypes = {
  seomatic: PropTypes.object
}

export default SEO
